module controllers {
    export module master {
        interface ICartageContractorUpdateScope extends ng.IScope {
        }

        interface ICartageContractorUpdateScopeParams extends ng.ui.IStateParamsService {
            comId: number;
        }

        export class cartageContractorUpdateCtrl {

            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$anchorScroll',
                '$uibModal',
                'bsLoadingOverlayService',
                '$stateParams',
                'entityService',
                '$state',
                '$timeout',
                '$transitions',
                'documentRepositoryService',
                'cartageContractorService',
                'transportModeService',
                "countryService",
            ];


            comId: number;
            cartageContractorMaster: interfaces.master.ICartageContractorMaster;

            breadCrumbDesc: string;
            showAll: boolean = false;

            isLoadingDetail: boolean = false;

            documentRepository: interfaces.documentManagement.IDocumentRepository;

            yesNoItems = [{
                id: null,
                label: 'Please Select',
            },{
                id: true,
                label: 'Yes',
            }, {
                id: false,
                label: 'No',
            }];

            //customs Code Grid
            apiCC: uiGrid.IGridApi;

            constructor(
                private $scope: ICartageContractorUpdateScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private bsLoadingOverlayService,
                private $stateParams: ICartageContractorUpdateScopeParams,
                private entityService: interfaces.applicationcore.IEntityService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                public documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                public cartageContractorService: services.master.cartageContractorService,
                public transportModeService: services.applicationcore.transportModeService,
                public countryService: interfaces.applicationcore.ICountryService,
            ) {

                this.comId = $stateParams.comId;

                var loadPromises: ng.IPromise<any>[] = [];

                loadPromises.push(this.loadCartageContractor(this.comId));

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return $q.all(loadPromises);
                    });
            }

            loadCartageContractor(Id: number) {
                return this.cartageContractorService.getCartageContractor(Id).query((data: interfaces.master.ICartageContractorMaster) => {

                    this.cartageContractorMaster = data;
                   

                  

                    this.$timeout(() => {
                        this.gvwCartageContractorCustomsCodes.data = this.cartageContractorMaster.CartageContractorCustomsCodeList;
                        console.log("this.cartageContractorMaster.CartageContractorCustomsCodeList;",data);
                        this.isLoadingDetail = false;
                    });
                    
                    this.breadCrumbDesc = this.cartageContractorMaster.Code + " - " + this.cartageContractorMaster.Name;
                    this.getDocuments(this.showAll);

                }
                    , (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
            }

            loadTransportModes() {
                return this.transportModeService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            SaveCartageContractor(): ng.IPromise<boolean> {
                var deferre = this.$q.defer<boolean>();

                if (this.apiCC) {
                    this.cartageContractorMaster.CartageContractorCustomsCodeList = _.map(this.apiCC.rowEdit.getDirtyRows(this.apiCC.grid), (o) => { return o.entity });
                }
                else {
                    this.cartageContractorMaster.CartageContractorCustomsCodeList = [];
                }

                this.cartageContractorService.save().save(this.cartageContractorMaster, (data: interfaces.applicationcore.IMessageHandler) => {
                    this.generalService.displayMessageHandler(data);

                    this.comId = Number(data.ID);

                    if (this.apiCC) {
                        this.apiCC.rowEdit.setRowsClean(this.cartageContractorMaster.CartageContractorCustomsCodeList);
                    }

                    deferre.resolve(true);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    deferre.resolve(false);
                });

                return deferre.promise;
            };

            Save() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveCartageContractor().then((data: boolean) => {
                            if (data) {
                                console.log("this.comId",this.comId);
                                this.loadCartageContractor(this.comId);
                                this.$anchorScroll("topAnchor");
                                this.$state.go("auth.MasterData.CartageContractor.Update", { comId: this.comId });
                            }
                        });
                    });

            }

            SaveClose() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.SaveCartageContractor().then((data) => {
                            if (data) {
                                this.$state.go("^");
                            } else {
                                this.$anchorScroll("topAnchor");
                            }
                        });
                    });
            }

            Close() {
                this.$state.go("^");
            }

            getDocuments(showAll:boolean) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'com.update'
                },
                    () => {
                        return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.cartageContractorMaster.Code, systemType: Enum.EnumSystemType.Company, ownerEntityId: this.cartageContractorMaster.EntityId,showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                            this.documentRepository = documentRepository;
                        }, (errorResponse) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        }).$promise;
                    });
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.documentRepository = null;
                return this.getDocuments(showAll);
            }

            selected(entity: interfaces.master.ICartageContractorCustomsCode,result : interfaces.master.ICustomsCargoCarrierViewModel ){
                entity.CustomsCode = result.Code
            }

            openCustomsCargoCarriers(entity: interfaces.master.ICartageContractorCustomsCode){
                this.$uibModal.open({
                    animation: true,
                    size: "md",
                    template: `
                    <div class="modal-header">
                        <h3 class="modal-title" id="modal-title">Customs Cargo Carriers</h3>
                    </div>
                    <div class="modal-body" id="modal-body">
                    <gts-message-display messages="Messages"></gts-message-display>
                        <div bs-loading-overlay bs-loading-overlay-reference-id="customs.cargo">
                            <div ui-grid="customsCargoCarriersCtrl.gvwGrid" ui-grid-pagination ui-grid-resize-columns ui-grid-selection class="gridFullScreen" ng-if="customsCargoCarriersCtrl.showGrid">
                                <div layout="column" layout-align="center center" class="grid-msg-overlay" ng-if="!customsCargoCarriersCtrl.gvwGrid.data || customsCargoCarriersCtrl.IsLoading">
                                    <div class="msg" ng-show="!customsCargoCarriersCtrl.gvwConList.data && !customsCargoCarriersCtrl.IsLoading">
                                        <span>No Rows</span>
                                    </div>
                                    <div class="loadingGrid" ng-show="vesselScheduleCtrl.IsLoading">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" type="button" ng-click="customsCargoCarriersCtrl.ok()">Ok</button>
                        <button class="btn btn-info" type="button" ng-click="customsCargoCarriersCtrl.cancel()">Close</button>
                    </div>`,
                    controller: class customsCargoCarriersCtrl {
                        showGrid: boolean = false;
                        IsLoading: boolean = false;
                        pageNumber: number = 1;
                        pageSize: number = 25;
                        apiGrid: uiGrid.IGridApi;
                        constructor(private $scope: ng.IScope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, 
                            private cartageContractorService: services.master.cartageContractorService, 
                            private generalService: interfaces.applicationcore.IGeneralService, 
                            private $q: ng.IQService,
                            private $timeout: ng.ITimeoutService,
                            private comId: number,
                            private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService) {

                            this.showGrid = true;
                        }

                        gvwGrid: uiGrid.IGridOptions = {
                            data: [],
                            enableFiltering: true,
                            useExternalFiltering: false,
                            enableCellEdit: false,
                            useExternalSorting: false,
                            multiSelect: false,
                            enableColumnResizing: true,
                            enableRowSelection: true,
                            enableFullRowSelection: true,
                            paginationPageSizes: [25, 50, 75, 100],
                            paginationPageSize: 25,
                            useExternalPagination: false,
                            enableRowHeaderSelection: false,
                            enableHorizontalScrollbar: 2,
                            enableVerticalScrollbar: 1,
                            onRegisterApi: (gridApi) => {
                                this.apiGrid = gridApi;
                               // gridApi.core.on.filterChanged(this.$scope, () => { this.loadCustomsCargoCarriers(this.comId) });
                               // gridApi.core.on.sortChanged(this.$scope, () => { this.loadCustomsCargoCarriers(this.comId) });
                               /* gridApi.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { 
                                    this.pageNumber = newPage;
                                    this.pageSize = pageSize;
                                    this.loadCustomsCargoCarriers(this.comId) 
                                });*/

                                this.$timeout(() => {
                                    this.loadCustomsCargoCarriers(this.comId);
                                });
                            },
                            columnDefs: [{
                                name: "ID",
                                displayName: "ID",
                                field: "Id",
                                visible: false,
                                enableFiltering: false
                            },
                            {
                                name: "Code",
                                displayName: "Code",
                                field: "Code",
                                width: 80,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                            },
                            {
                                name: "Name",
                                displayName: "Cargo Carrier",
                                field: "Name",
                                width: 200,
                                cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                                filterHeaderTemplate: `
                                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                            <div class="input-group-sm">
                                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                            </div>
                                        </div>`
                            },
                             {
                                name: "IsSea",
                                displayName: "Sea",
                                enableFiltering: false,
                                enableCellEdit: false,
                                enableCellEditOnFocus: false,
                                cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.IsSea">`,
                                width: 60
                            }
                            ,
                            {
                               name: "IsAir",
                               displayName: "Air",
                               enableFiltering: false,
                               enableCellEdit: false,
                               enableCellEditOnFocus: false,
                               cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.IsAir">`,
                               width: 60
                           } ,
                           {
                              name: "IsRoad",
                              displayName: "Road",
                              enableFiltering: false,
                              enableCellEdit: false,
                              enableCellEditOnFocus: false,
                              cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.IsRoad">`,
                              width: 60
                          } ,
                          {
                             name: "IsRail",
                             displayName: "Rail",
                             enableFiltering: false,
                             enableCellEdit: false,
                             enableCellEditOnFocus: false,
                             cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.IsRail">`,
                             width: 60
                         } ,
                         {
                            name: "IsPost",
                            displayName: "Post",
                            enableFiltering: false,
                            enableCellEdit: false,
                            enableCellEditOnFocus: false,
                            cellTemplate: `<input type="checkbox" ng-disabled="true" ng-model="row.entity.IsPost">`,
                            width: 60
                        }]
                        };
                        //Load Vessel
                        public loadCustomsCargoCarriers(comId:number) {
                            if (comId) {
                                
                               
                                
                                this.IsLoading = true;
                                this.cartageContractorService.getCustomsCargoCarriersList().query({comId:this.comId},(data) => {
                                    this.gvwGrid.data = data;
                                    if(data && data.length > 0) {
                                        this.gvwGrid.totalItems = data[0].NumRecords;
                                    } else {
                                        this.gvwGrid.totalItems = 0;
                                    }

                                    this.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data, this.$scope);
                                    this.IsLoading = false;
                                });
                            } else {

                            }
                        }


                        cancel() {
                            this.$uibModalInstance.dismiss();
                        }

                        ok() {
                            if(this.apiGrid.selection.getSelectedRows().length > 0) {
                                var selectedRow = this.apiGrid.selection.getSelectedRows()[0];

                                this.$uibModalInstance.close(selectedRow);
                            }
                        }
                        
                    },
                    controllerAs: "customsCargoCarriersCtrl",
                    resolve: {
                        comId: () => {
                            return this.comId;
                        }
                    }
                }).result.then(
                    (result: interfaces.master.ICustomsCargoCarrierViewModel) => {
                        if(result) {
                            this.selected(entity,result);
                        }
                    }
                );
            }

            /*******************  Customs Code Grid Logic *************************/

            checkBoxActiveChanged(entity: interfaces.master.ICartageContractorCustomsCode){
                this.setRowsDirtyCC(entity);
            }

            removeInlineCC(entity: interfaces.master.ICartageContractorCustomsCode) {
                if (entity.ID > 0)
                {
                    entity.IsActive = false;
                    this.apiCC.rowEdit.setRowsDirty([entity]);                    
                    this.SaveCartageContractor();
                }
                else
                {
                    _.remove(this.gvwCartageContractorCustomsCodes.data, (o) => {
                        return o === entity;
                    });
                }
            }

            addNewCC() {
                var add = <interfaces.master.ICartageContractorCustomsCode>{
                    CartageContractorId: this.cartageContractorMaster.Id,
                    IsActive: true,
                };
                (<Array<any>>(this.gvwCartageContractorCustomsCodes.data)).splice(0, 0, add);
            };

            countryChanged(model: interfaces.applicationcore.IDropdownModel, entity: interfaces.master.ICartageContractorCustomsCode) {
                if (model) {
                    this.setRowsDirtyCC(entity);
                }
            }

            customsCodeChanged(entity: interfaces.master.ICartageContractorCustomsCode) {
                if (entity) {
                    this.setRowsDirtyCC(entity);
                }
            }     
            
            foreignCustomsCodeChanged(entity: interfaces.master.ICartageContractorCustomsCode) {
                if (entity) {
                    this.setRowsDirtyCC(entity);
                }
            }               

            setRowsDirtyCC(entity) {
                if (this.apiCC) {
                    this.apiCC.rowEdit.setRowsDirty([entity]);
                }
            }

            
            loadCountryList() {
                var defered = this.$q.defer();

                this.countryService.getDropdownList().query({}, (data: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(data);
                }, () => {
                    defered.resolve([]);
                });


                return defered.promise;
            }


            registerCustomsCodeApi(gridApi: uiGrid.IGridApi) {
                this.apiCC = gridApi;
            }

            gvwCartageContractorCustomsCodes: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: true,
                useExternalSorting: false,
                multiSelect: false,
                enableRowHeaderSelection: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableHorizontalScrollbar: 2,
                rowTemplate: '<div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" ng-class="col.colIndex()" class="ui-grid-cell" ui-grid-cell></div>',
                rowEditWaitInterval: -1,
                onRegisterApi: (gridApi) => { this.registerCustomsCodeApi(gridApi); },
                columnDefs: [
                    {
                        name: "EDIT",
                        displayName: "",
                        field: "Id",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.cartageContractorUpdateCtrl.removeInlineCC(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`,
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEdit: false,
                        width: 57,
                        enableCellEditOnFocus: false,
                        headerCellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.cartageContractorUpdateCtrl.addNewCC()" >
                                <span class="fa fa-plus"></span>
                            </button>
                        </div>`
                    }, {
                        name: 'Country',
                        displayName: '* Country',
                        field: 'Country',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ng-change="grid.appScope.cartageContractorUpdateCtrl.countryChanged(model, row.entity)" ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.cartageContractorUpdateCtrl.loadCountryList()"></gts-dropdown>
                                </form>`,
                        enableSorting: false,
                        width: 250
                    },
                    {
                        name: 'CustomsCode',
                        displayName: 'Customs Code',
                        field: 'CustomsCode',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                <p class="input-group  input-group-sm">
                                    <input class="form-control" type="text" maxlength="30" ng-model="row.entity.CustomsCode" name="CustomsCode" aria-label="Customs Code" ng-change="grid.appScope.cartageContractorUpdateCtrl.customsCodeChanged(row.entity)" ng-disabled="grid.appScope.cartageContractorUpdateCtrl.cartageContractorMaster.LockdownType >= 2" />
                                    <span class="input-group-btn">
                                        <button type="button" class="btn btn-default" ng-click="grid.appScope.cartageContractorUpdateCtrl.openCustomsCargoCarriers(row.entity)"  ng-disabled="ngDisabled"><i class="fa fa-eye"></i></button>
                                    </span>
                                </p>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    {
                        name: 'ForeignCustomsCode',
                        displayName: 'Foreign Registered Customs Code',
                        field: 'ForeignCustomsCode',
                        enableCellEditOnFocus: true,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        editableCellTemplate: `
                                <form name="inputForm">
                                <p class="input-group  input-group-sm">
                                    <input class="form-control" type="text" maxlength="30" ng-model="row.entity.ForeignCustomsCode" name="ForeignCustomsCode" aria-label="Foreign Customs Code" ng-change="grid.appScope.cartageContractorUpdateCtrl.foreignCustomsCodeChanged(row.entity)" ng-disabled="grid.appScope.cartageContractorUpdateCtrl.cartageContractorMaster.LockdownType >= 2" />
                                </p>
                                </form>`,
                        enableSorting: false,
                        width: 300
                    },
                    , {
                        name: "IsActive",
                        displayName: "Active",
                        enableFiltering: false,
                        enableCellEdit: true,
                        enableCellEditOnFocus: true,
                        cellTemplate: `<input type="checkbox"  ng-disabled="cartageContractorUpdateCtrl.cartageContractorMaster.LockdownType >= 2" ng-model="row.entity.IsActive" ng-change="grid.appScope.cartageContractorUpdateCtrl.checkBoxActiveChanged(row.entity)">`,
                        editableCellTemplate: ` <form name="inputForm">
                                                    <p class="input-group-sm">
                                                        <input type="checkbox" ng-disabled="cartageContractorUpdateCtrl.cartageContractorMaster.LockdownType >= 2" ng-model="row.entity.IsActive">
                                                    </p>
                                                </form>`,
                        width: 60
                    }
                ]
            };

           

            
        };

        angular.module("app").controller("cartageContractorUpdateCtrl", controllers.master.cartageContractorUpdateCtrl);
    }
}
